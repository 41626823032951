<template>
  <ProductTierLevelsMolecule
    :loading="this.product === null && this.$apollo.queries.product.loading"
    :error="error"
    :productType="product?.type"
    :tierLevels="localTierLevels"
    :canAddNewTierLevel="canAddNewTierLevel"
    :availableLanguages="availableLanguages"
    :activeLanguageId="activeLanguageId"
    :addablePermissions="permissions"
    :addableAssetDimensions="assetDimensions"
    :addableWidgetTypes="widgetTypes"
    :addableBundleRelations="addableBundleRelations"
    :addableBundleRelationsLoading="addableBundleRelationsLoading"
    @set-active-language-id="$emit('set-active-language-id', $event)"
    @set-translated-text="setTranslatedText"
    @save-translated-text="saveTranslatedText"
    @restore-translated-text="restoreTranslatedText"
    @save-all-translated-texts="saveAllTranslatedTexts"
    @restore-all-translated-texts="restoreAllTranslatedTexts"
    @add-new-tier-level="addNewTierLevel"
    @add-relation="addRelation"
    @remove-relation="removeRelation"
    @remove-tier-level="removeTierLevel"
    @save-tier-level="saveTierLevel"
    @add-bundle-relation="addBundleRelation"
    @remove-bundle-relation="removeBundleRelation"
    @bundle-search-change="bundleSearchSearchChange"
    @bundle-dropdown-opened="bundleDropdownOpened"
    @bundle-dropdown-closed="bundleDropdownClosed"
  />
</template>

<script>
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'vue-debounce'

import { FlashMessages } from '@common/singletons'

import ProductTierLevelsMolecule from '../Molecules/ProductTierLevelsMolecule'

import PRODUCT_QUERY from '#/graphql/marketplace/productQuery.gql'
import CREATE_ONE_PRODUCT_TIER_LEVEL_MUTATION from '#/graphql/marketplace/createOneProductTierLevelMutation.gql'
import UPDATE_ONE_PRODUCT_TIER_LEVEL_MUTATION from '#/graphql/marketplace/updateOneProductTierLevelMutation.gql'
import CREATE_ONE_TRANSLATED_TEXT_MUTATION from '#/graphql/marketplace/createOneTranslatedTextMutation.gql'
import UPDATE_ONE_TRANSLATED_TEXT_MUTATION from '#/graphql/marketplace/updateOneTranslatedTextMutation.gql'
import PRODUCT_TIER_LEVELS_ADDABLE_QUERY from '#/graphql/marketplace/productTierLevelsAddableQuery.gql'
import AVAILABLE_LANGUAGE_ENUMS_QUERY from '#/graphql/marketplace/availableLanguageEnumsQuery.gql'
import ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensionsBasic.gql'
import WIDGET_TYPES_QUERY from '#/graphql/widgetTypes/widgetTypes.gql'
import PERMISSIONS_BASIC from '#/graphql/permissions/permissionsBasic.gql'

function getCompatibleTypeIdsKey(__typename) {
  if (__typename === 'Permission') {
    __typename = 'UserPermission'
  }
  return `${__typename.charAt(0).toLowerCase()}${__typename.slice(1)}Ids`
}

function getCompatibleTypeLocalKey(__typename) {
  if (__typename === 'Permission') {
    __typename = 'UserPermission'
  }
  return `${__typename.charAt(0).toLowerCase()}${__typename.slice(1)}s`
}

export default {
  props: {
    productId: {
      type: String,
    },
    activeLanguageId: {
      type: String,
      required: true,
    },
  },
  components: {
    ProductTierLevelsMolecule,
  },
  data() {
    return {
      product: null,
      localTierLevels: [],
      availableLanguages: [],
      permissions: [],
      assetDimensions: [],
      widgetTypes: [],
      error: null,
      addableBundleRelations: [],
      addableBundleRelationsLoading: false,
      activeTierLevelId: null,
    }
  },
  computed: {
    canAddNewTierLevel() {
      return !this.localTierLevels.some(s => s.isNew)
    },
  },
  created() {
    this.searchBundleRelations = debounce(params => {
      this.searchBundleRelationsDebounced(params)
    }, 150)
  },
  methods: {
    setTranslatedText($event, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      const localTranslatedText = localTierLevel?.localTranslatedTexts.find(f => f.id === $event.id)

      const productTierLevelTranslation = this.product?.tierLevels
        .find(f => f.id === tierLevelId)
        ?.translatedTexts.find(f => f.id === $event.id)
      const isDirty = $event.text !== productTierLevelTranslation?.text
      const isCompleted = $event.text?.trim()?.length > 0
      localTranslatedText.text = $event.text
      localTranslatedText.isDirty = isDirty
      localTranslatedText.isCompleted = isCompleted
      localTranslatedText.canSave = isDirty && isCompleted
      localTranslatedText.canRestore = isDirty
      localTierLevel.canSaveAllTranslatedTexts = localTierLevel.localTranslatedTexts.some(s => s.canSave)
      localTierLevel.canRestoreAllTranslatedTexts = localTierLevel.localTranslatedTexts.some(s => s.isDirty)
      localTierLevel.hasSaveTranslatedTexts = !localTierLevel.isNew
      // trigger reactivity
      this.$set(
        localTierLevel,
        'canSave',
        localTierLevel.localTranslatedTexts.every(e => e.isCompleted),
      )
    },
    async saveTranslatedText(id, tierLevelId) {
      const translatedText = this.localTierLevels.find(f => f.id === tierLevelId)?.localTranslatedTexts.find(f => f.id === id)
      if (!translatedText) {
        throw new Error(`did not find '${id}' in localTranslatedTexts of tierLevel ${tierLevelId}`)
      }
      const res = translatedText.isNew
        ? await this.createTranslatedText(translatedText, tierLevelId)
        : await this.updateTranslatedText(translatedText)
      if (res.data) {
        this.$apollo.queries.product.refetch()
      }
    },
    async createTranslatedText(translatedText, tierLevelId) {
      return await this.$apollo.mutate({
        mutation: CREATE_ONE_TRANSLATED_TEXT_MUTATION,
        variables: {
          data: {
            text: translatedText.text,
            language: translatedText.language,
            translatableId: tierLevelId,
            translatableType: 'ProductTierLevel',
            translatedField: 'description',
          },
        },
      })
    },
    async updateTranslatedText(translatedText) {
      return await this.$apollo.mutate({
        mutation: UPDATE_ONE_TRANSLATED_TEXT_MUTATION,
        variables: {
          where: {
            id: translatedText.id,
          },
          data: {
            text: {
              set: translatedText.text,
            },
          },
        },
      })
    },
    restoreTranslatedText(id, tierLevelId) {
      const productTierLevelTranslation = this.product?.tierLevels.find(f => f.id === tierLevelId)?.translatedTexts.find(f => f.id === id)
      if (!productTierLevelTranslation) {
        throw new Error(`did not find '${id}' in product.translatedTexts`)
      }
      this.setTranslatedText(productTierLevelTranslation, tierLevelId)
    },
    async saveAllTranslatedTexts(tierLevelId) {
      const saveableIds = this.localTierLevels
        .find(f => f.id === tierLevelId)
        ?.localTranslatedTexts.filter(f => f.canSave)
        .map(m => m.id)
      const promises = saveableIds.map(id => this.saveTranslatedText(id, tierLevelId))
      await Promise.all(promises)
      this.$apollo.queries.product.refetch()
    },
    restoreAllTranslatedTexts(tierLevelId) {
      const dirtyIds = this.localTierLevels
        .find(f => f.id === tierLevelId)
        ?.localTranslatedTexts.filter(f => f.isDirty)
        .map(m => m.id)
      dirtyIds.forEach(id => {
        this.restoreTranslatedText(id, tierLevelId)
      })
    },
    addNewTierLevel() {
      this.localTierLevels.push({
        id: uuidv4(),
        isNew: true,
        hasSaveTranslatedTexts: false,
        tierLevel: this.localTierLevels.length + 1,
        canSaveAllTranslatedTexts: false,
        canRestoreAllTranslatedTexts: false,
        permissions: [],
        assetDimensions: [],
        widgetTypes: [],
        bundles: [],
        bundledBy: [],
        localTranslatedTexts: this.availableLanguages.map(language => ({
          id: uuidv4(),
          text: '',
          language,
          isNew: true,
          isDirty: true,
          canSave: false,
          isCompleted: false,
        })),
      })
    },
    async addRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      if (!relation?.__typename) {
        throw new Error(`missing '__typename' in relation`)
      }
      if (!localTierLevel.isNew) {
        const variables = {
          where: {
            id: localTierLevel.id,
          },
          data: {
            [getCompatibleTypeIdsKey(relation.__typename)]: {
              push: relation.id,
            },
          },
        }
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_ONE_PRODUCT_TIER_LEVEL_MUTATION,
            variables,
          })
          FlashMessages.$emit('success', `Successfully added ${relation.__typename}`, {
            timeout: 1500,
          })
        } catch (err) {
          FlashMessages.$emit('error', new Error(`Could not add ${relation.__typename}`), {
            timeout: 1500,
          })
          throw err
        }
      }
      if (relation.__typename === 'Permission') {
        localTierLevel.permissions.push(relation)
      } else if (relation.__typename === 'AssetDimension') {
        localTierLevel.assetDimensions.push(relation)
      } else if (relation.__typename === 'WidgetType') {
        localTierLevel.widgetTypes.push(relation)
      } else {
        throw new Error(`unhandled __typename '${relation.__typename}'`)
      }
    },
    async removeRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      if (!relation?.__typename) {
        throw new Error(`missing '__typename' in relation`)
      }
      const compatibleTypes = ['Permission', 'AssetDimension', 'WidgetType']
      if (!compatibleTypes.includes(relation.__typename)) {
        throw new Error(`__typename '${relation.__typename}' is not compatible`)
      }
      if (!localTierLevel.isNew) {
        const variables = {
          where: {
            id: localTierLevel.id,
          },
          data: {
            [getCompatibleTypeIdsKey(relation.__typename)]: {
              set: localTierLevel[getCompatibleTypeLocalKey(relation.__typename)].filter(f => f.id !== relation.id).map(m => m.id),
            },
          },
        }
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_ONE_PRODUCT_TIER_LEVEL_MUTATION,
            variables,
          })
          FlashMessages.$emit('success', `Successfully removed ${relation.__typename}`, {
            timeout: 1500,
          })
        } catch (err) {
          FlashMessages.$emit('error', new Error(`Could not remove ${relation.__typename}`), {
            timeout: 1500,
          })
          throw err
        }
      }
      if (relation.__typename === 'Permission') {
        localTierLevel.permissions = localTierLevel.permissions.filter(f => f.id !== relation.id)
      } else if (relation.__typename === 'AssetDimension') {
        localTierLevel.assetDimensions = localTierLevel.assetDimensions.filter(f => f.id !== relation.id)
      } else if (relation.__typename === 'WidgetType') {
        localTierLevel.widgetTypes = localTierLevel.widgetTypes.filter(f => f.id !== relation.id)
      } else {
        throw new Error(`unhandled __typename '${relation.__typename}'`)
      }
    },
    removeTierLevel(tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      if (!localTierLevel.isNew) {
        // TODO: add confirm delete
      }
      this.localTierLevels = this.localTierLevels.filter(f => f.id !== tierLevelId)
    },
    async saveTierLevel(tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      let relationKey = null
      if (this.product?.type === 'APP') {
        relationKey = 'bundledBy'
      } else if (this.product?.type === 'BUNDLE') {
        relationKey = 'bundles'
      } else {
        throw new Error(`unhandled product type '${this.product?.type}' in saveTierLevel`)
      }
      const variables = {
        data: {
          tierLevel: localTierLevel.tierLevel,
          permissionIds: {
            set: localTierLevel.permissions.map(m => m.id),
          },
          assetDimensionIds: {
            set: localTierLevel.assetDimensions.map(m => m.id),
          },
          widgetTypeIds: {
            set: localTierLevel.widgetTypes.map(m => m.id),
          },
          product: {
            connect: {
              id: this.productId,
            },
          },
        },
      }
      variables.data[relationKey] = {
        connect: localTierLevel?.[relationKey].map(m => ({ id: m.id })),
      }
      if (!localTierLevel.isNew) {
        throw new Error(`Saving existing tiers not supported (because not needed)`)
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_ONE_PRODUCT_TIER_LEVEL_MUTATION,
          variables,
        })
        for await (const translatedText of localTierLevel.localTranslatedTexts) {
          await this.$apollo.mutate({
            mutation: CREATE_ONE_TRANSLATED_TEXT_MUTATION,
            variables: {
              data: {
                text: translatedText.text,
                language: translatedText.language,
                translatableId: res.data.createOneProductTierLevel.id,
                translatableType: 'ProductTierLevel',
                translatedField: 'description',
              },
            },
          })
        }
        FlashMessages.$emit('success', `Successfully saved Tier level ${localTierLevel.tierLevel}`, {
          timeout: 1500,
        })
        this.$set(localTierLevel, 'canSave', false)
        this.$set(localTierLevel, 'isNew', false)
        this.$set(localTierLevel, 'id', res.data.id)
      } catch (err) {
        FlashMessages.$emit('error', new Error(`Could not save Tier level ${localTierLevel.tierLevel}`), {
          timeout: 3000,
        })
        throw err
      }
    },
    async addBundleRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      let relationKey = null
      if (this.product?.type === 'APP') {
        relationKey = 'bundledBy'
      } else if (this.product?.type === 'BUNDLE') {
        relationKey = 'bundles'
      } else {
        throw new Error(`unhandled product type '${this.product?.type}' in addBundleRelation`)
      }
      if (!localTierLevel.isNew) {
        try {
          const variables = {
            where: {
              id: localTierLevel.id,
            },
            data: {},
          }
          variables.data[relationKey] = { connect: { id: relation.id } }
          await this.$apollo.mutate({
            mutation: UPDATE_ONE_PRODUCT_TIER_LEVEL_MUTATION,
            variables,
          })
          FlashMessages.$emit('success', `Successfully added ${relation.__typename}`, {
            timeout: 1500,
          })
        } catch (err) {
          FlashMessages.$emit('error', new Error(`Could not add ${relation.__typename}`), {
            timeout: 1500,
          })
          throw err
        }
      }
      localTierLevel?.[relationKey].push(relation)
    },
    async removeBundleRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      let relationKey = null
      if (this.product?.type === 'APP') {
        relationKey = 'bundledBy'
      } else if (this.product?.type === 'BUNDLE') {
        relationKey = 'bundles'
      } else {
        throw new Error(`unhandled product type '${this.product?.type}' in removeBundleRelation`)
      }
      if (!localTierLevel.isNew) {
        try {
          const variables = {
            where: {
              id: localTierLevel.id,
            },
            data: {},
          }
          variables.data[relationKey] = { disconnect: { id: relation.id } }
          await this.$apollo.mutate({
            mutation: UPDATE_ONE_PRODUCT_TIER_LEVEL_MUTATION,
            variables,
          })
          FlashMessages.$emit('success', `Successfully removed ${relation.__typename}`, {
            timeout: 1500,
          })
        } catch (err) {
          FlashMessages.$emit('error', new Error(`Could not remove ${relation.__typename}`), {
            timeout: 1500,
          })
          throw err
        }
      }
      localTierLevel[relationKey] = localTierLevel?.[relationKey].filter(f => f.id !== relation.id)
    },
    getLocalTierLevelOrThrow(tierLevelId) {
      const idx = this.localTierLevels.findIndex(f => f.id === tierLevelId)
      if (idx === -1) {
        throw new Error(`did not find '${tierLevelId}' in localTierLevels`)
      }
      return { idx, localTierLevel: this.localTierLevels[idx] }
    },
    async bundleSearchSearchChange(searchQuery, tierLevelId) {
      await this.searchBundleRelations({ searchQuery, tierLevelId })
    },
    async bundleDropdownOpened(tierLevelId) {
      await this.searchBundleRelations({ tierLevelId })
    },
    bundleDropdownClosed() {
      this.addableBundleRelations = []
    },
    async searchBundleRelationsDebounced({ searchQuery, tierLevelId }) {
      this.addableBundleRelationsLoading = true
      try {
        const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
        // filter out tierLevels that are already taken by this
        // tierLevel or tierLevels with lower level
        const ignoredTierLevelIds = this.localTierLevels
          .filter(f => f.tierLevel <= localTierLevel.tierLevel)
          .flatMap(fm => [...fm.bundles.map(m => m.id), ...fm.bundledBy.map(m => m.id)])
        const ignoredProductIds = [...localTierLevel.bundles.map(m => m.product.id), ...localTierLevel.bundledBy.map(m => m.product.id)]
        const variables = {
          where: {
            id: {
              notIn: [...ignoredTierLevelIds],
            },
            product: {
              isNot: {
                OR: [
                  {
                    type: {
                      equals: this.product?.type,
                    },
                  },
                  {
                    id: {
                      in: [...ignoredProductIds],
                    },
                  },
                ],
              },
            },
          },
          take: 10,
        }
        if (this.productId) {
          variables.where.product.isNot.OR[1].id.in.push(this.productId)
        }
        if (searchQuery) {
          variables.where.product.is = {
            name: {
              contains: searchQuery,
              mode: 'insensitive',
            },
          }
        }
        const res = await this.$apollo.query({
          query: PRODUCT_TIER_LEVELS_ADDABLE_QUERY,
          variables,
        })
        this.addableBundleRelations = res.data.productTierLevels.map(m => ({
          id: m.id,
          label: `${m.product?.name} - Tier level ${m.tierLevel}`,
          // make tierlevel, product and __typename available in addables
          // so they can be pushed directly to bundles/bundledBy arrays when added
          tierLevel: m.tierLevel,
          product: m.product,
          __typename: m.__typename,
        }))
      } catch (err) {
        throw new Error(err)
      } finally {
        this.addableBundleRelationsLoading = false
      }
    },
  },
  apollo: {
    product: {
      query: PRODUCT_QUERY,
      variables() {
        return {
          where: {
            id: this.productId,
          },
        }
      },
      skip() {
        return !this.productId || this.availableLanguages?.length < 1
      },
      result({ data }) {
        this.error = null
        this.localTierLevels = cloneDeep(data?.product?.tierLevels).map(tierLevel => {
          return {
            ...tierLevel,
            hasSaveTranslatedTexts: true,
            canSaveAllTranslatedTexts: false,
            canRestoreAllTranslatedTexts: false,
            localTranslatedTexts: this.availableLanguages.reduce((localTranslatedTexts, language) => {
              const translatedText = tierLevel?.translatedTexts.find(f => f.language === language)
              if (translatedText) {
                localTranslatedTexts.push({
                  ...translatedText,
                  isNew: false,
                  isDirty: false,
                  canSave: false,
                  isCompleted: translatedText.text?.trim()?.length > 0,
                })
              } else {
                localTranslatedTexts.push({
                  id: uuidv4(),
                  text: '',
                  language,
                  isNew: true,
                  isDirty: true,
                  canSave: false,
                  isCompleted: false,
                })
              }
              return localTranslatedTexts
            }, []),
          }
        })
      },
      error(err) {
        this.error = err
      },
    },
    availableLanguages: {
      query: AVAILABLE_LANGUAGE_ENUMS_QUERY,
      update: data => {
        return (data?.availableLanguageEnums?.enumValues ?? []).map(m => m.name)
      },
    },
    assetDimensions: {
      query: ASSET_DIMENSIONS_QUERY,
    },
    widgetTypes: {
      query: WIDGET_TYPES_QUERY,
    },
    permissions: {
      query: PERMISSIONS_BASIC,
    },
  },
}
</script>
