var render = function () {
  var _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductTierLevelsMolecule', {
    attrs: {
      "loading": this.product === null && this.$apollo.queries.product.loading,
      "error": _vm.error,
      "productType": (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.type,
      "tierLevels": _vm.localTierLevels,
      "canAddNewTierLevel": _vm.canAddNewTierLevel,
      "availableLanguages": _vm.availableLanguages,
      "activeLanguageId": _vm.activeLanguageId,
      "addablePermissions": _vm.permissions,
      "addableAssetDimensions": _vm.assetDimensions,
      "addableWidgetTypes": _vm.widgetTypes,
      "addableBundleRelations": _vm.addableBundleRelations,
      "addableBundleRelationsLoading": _vm.addableBundleRelationsLoading
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        return _vm.$emit('set-active-language-id', $event);
      },
      "set-translated-text": _vm.setTranslatedText,
      "save-translated-text": _vm.saveTranslatedText,
      "restore-translated-text": _vm.restoreTranslatedText,
      "save-all-translated-texts": _vm.saveAllTranslatedTexts,
      "restore-all-translated-texts": _vm.restoreAllTranslatedTexts,
      "add-new-tier-level": _vm.addNewTierLevel,
      "add-relation": _vm.addRelation,
      "remove-relation": _vm.removeRelation,
      "remove-tier-level": _vm.removeTierLevel,
      "save-tier-level": _vm.saveTierLevel,
      "add-bundle-relation": _vm.addBundleRelation,
      "remove-bundle-relation": _vm.removeBundleRelation,
      "bundle-search-change": _vm.bundleSearchSearchChange,
      "bundle-dropdown-opened": _vm.bundleDropdownOpened,
      "bundle-dropdown-closed": _vm.bundleDropdownClosed
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }